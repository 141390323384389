import React, { useState, Component } from 'react'
import { Link } from 'react-router-dom';
import Create from './Travels/Create';

const  Content  = () => {
  const [optionMenu, setOptionMenu] = useState(0)

  const optionHandler = (opt) => {
    console.log(opt)
  }
  
  const selectOption = (opt) => {
    console.log(opt)
  }
      return (
        <div className='main-intranet'>
          <div className='row'>
            <button class="main-btn btn-filled col-3" onClick={() => optionHandler(1)}>GESTION VIAJES</button>
            <button class="main-btn btn-filled col-3" onClick={() => optionHandler(2)}>PAQUETES </button>
            <button class="main-btn btn-filled col-3" onClick={() => optionHandler(3)}>CLIENTES </button>
            <button class="main-btn btn-filled col-3" onClick={() => optionHandler(4)}>PROVEEDORES </button>
          </div>

          <div className='row'>
            <div className="about-text-box">
                <div className="row align-items-center">
                      <h2 className='col-12 text-center'><b>GESTIONA TUS VIAJES</b></h2>
                    <div className="col-lg-4">
                        <div className="about-img">
                            <img src={process.env.PUBLIC_URL + "/assets/img/turismo/expedicion.jpg"} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="about-text">
                            <h3>SELECCIONA</h3>
                            <p>
                                Crea, edita y comparte tus próximas salidas 
                            </p>

                            <Link className="main-btn btn-filled" onClick={() => selectOption(1)}>CREAR</Link><br/><br/>
                            <Link className="main-btn btn-filled" onClick={() => selectOption(2)}>EDITAR</Link><br/><br/>
                            <Link className="main-btn btn-filled" onClick={() => selectOption(3)}>VER VIAJES</Link>
                        </div>
                    </div>room-booking-form
                </div>
            </div>
          </div>
          <div className='row'>
            <Create />
          </div>
        </div>
      )
  }

  export default Content;
  