import React, { useState, Component } from 'react';
import DatePicker from "react-datepicker";

const travel = { 
  id: null,
  dateCreated: null,
  initialDate: null,
  finalDate: null,
  packageId: null,
  passengersTotal: null,
  travelId: null,
  passengersAvailable: null
 }

const  Create  = () => {
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [departureDate, setDepartureDate] = useState(new Date());
  const [travelInit, setTravelInit] = useState(travel);

  const saveTravel = (obj) => {
    const aux = { ...travelInit }
    // aux[]
  }

  const handleValue  = (e, id) => {
    const aux = { ...travelInit }

    console.log(e.target.value)
    console.log(id)
    console.log(arrivalDate)
    console.log(departureDate)
    aux[id] = e.target.value
    if (arrivalDate || departureDate) {
      if (arrivalDate) aux.initialDate = arrivalDate
      if (departureDate) aux.finalDate = departureDate
    }
    console.log(aux)
    setTravelInit(aux)
  }
  return (
    <div className='col-12'>
        <div className="room-booking-form col-6">
            <h3 className="title">AGREGA UN NUEVO VIAJE</h3>
            <form action="#">
                <div className="input-group input-group-two left-icon mb-20">
                    <label htmlFor="arrival-date">Fecha de salida</label>
                    <div className="icon"><i className="fal fa-calendar-alt" /></div>
                    <DatePicker selected={arrivalDate} onChange={(date) => setArrivalDate(date)} />
                </div>
                <div className="input-group input-group-two left-icon mb-20">
                    <label htmlFor="departure-date">Fecha de retorno</label>
                    <div className="icon"><i className="fal fa-calendar-alt" /></div>
                    <DatePicker selected={departureDate} onChange={(date) => setDepartureDate(date)} />
                </div>
                <div className="input-group input-group-two left-icon mb-20">
                    <label htmlFor="room">Paquete</label>
                    <div className="nice-select">
                        <select name="room" id="packageId" onChange={(value, id) => handleValue(value, "packageId")}>
                            <option value={1}>Humantay</option>
                            <option value={2}>Salkantay</option>
                            <option value={4}>MachuPicchu</option>
                            <option value={8}>Montaña 7 colores</option>
                        </select>
                    </div>
                </div>
                <div className="input-group input-group-two left-icon mb-20">
                    <label htmlFor="departure-date">Pasajeros</label>
                    <div className="nice-select">
                        <select name="guest" id="passengersTotal" onChange={(value, id) => handleValue(value, "passengersTotal")}>
                            <option value={8}>8</option>
                            <option value={10}>10</option>
                            <option value={12}>12</option>
                            <option value={15}>15</option>
                        </select>
                    </div>
                </div>
                <div className="input-group">
                    <button className="main-btn btn-filled" onClick={() => saveTravel(travelInit)}>AGREGAR VIAJE</button>
                </div>
            </form>
        </div>
        {/* TABLA VIAJES */}
      <table style={{border: "1px solid", width: "auto", borderCollapse: "inherit"}}>
        <tr style={{border: "1px solid"}}>
          <th style={{border: "1px solid"}}>FECHA</th>
          <th style={{border: "1px solid"}}>PAQUETE</th>
          <th style={{border: "1px solid"}}>ASIENTOS DISPONIBLES</th>
          <th style={{border: "1px solid"}}>ASIENTOS CONFIRMADOS</th>
        </tr>
        <tbody>
        <tr style={{border: "1px solid"}}>
          <td style={{border: "1px solid"}}>Peter</td>
          <td style={{border: "1px solid"}}>Peter</td>
          <td style={{border: "1px solid"}}>Peter</td>
          <td style={{border: "1px solid"}}>Griffin</td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Create;